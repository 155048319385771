function checkIsOutsideIndia(latitude, longitude) {
    return latitude && longitude && (
        latitude < 8.4 || latitude > 37.6 || longitude < 68.7 || longitude > 97.25
    );
}

export default checkIsOutsideIndia;




